import React from "react";
import OutlinedButton from "./OutlinedButton";
import { Box, Typography } from "@mui/material";
import HelpImg from "../assets/images/help-img.webp";
import { useNavigate } from "react-router-dom";

const MainContent = () => {
  const navigate = useNavigate();
  const outlineBtnStyle = {
    mx: "auto",
    p: { xs: "12px !important", sm: "16px !important" },
    background: "white",
    width: "100%",
    display: "block",
    textAlign: "left",
    fontSize: { xs: "14px !important", sm: "16px !important" },
    "&.MuiButton-root:hover": {
      background: "#fa6900 !important",
      color: "#ffffff !important",
    },
  };
  return (
    <Box
      sx={{
        p: 1.75,
        pb: 2.25,
        mt: 1.5,
        backgroundColor: "#ffeee2",
        borderRadius: "14px",
        color: "#231f20",
      }}
    >
      <Box display="flex" alignItems="center" sx={{ mb: 1.5, gap: "10px" }}>
        <img
          src={HelpImg}
          style={{
            display: "block",
            width: "28px",
            height: "28px",
            objectFit: "cover",
            objectPosition: "top",
          }}
          alt="Help icon"
        />

        <Typography
          variant="h2"
          fontWeight="700"
          sx={{
            mb: 0,
            fontFamily: "'Fredoka', sans-serif",
            fontSize: {
              xs: 24,
              sm: 24,
            },
          }}
        >
          {" "}
          Get unstuck immediately
        </Typography>
      </Box>

      <Typography
        variant="h5"
        fontWeight="500"
        sx={{
          mb: 1.25,
          fontSize: "16px",
        }}
      >
        Let’s pinpoint your 1 Small Change for Maximum Results.
      </Typography>

      <Typography
        variant="h5"
        fontSize={18}
        fontWeight="500"
        sx={{
          mb: 1.25,
          fontSize: "16px",
        }}
      >
        How do you want to do this?
      </Typography>

      <OutlinedButton
        sx={{ ...outlineBtnStyle, mt: 2 }}
        onClick={() => {
          return navigate("/help");
        }}
      >
        I want to DIY. Give me free pointers
      </OutlinedButton>
      <OutlinedButton
        sx={{ ...outlineBtnStyle, mt: 1 }}
        onClick={() => {
          return navigate("/help?content=coaching");
        }}
      >
        I want to gauge fit with your coaching program
      </OutlinedButton>
    </Box>
  );
};

export default MainContent;
