import { Box, Button, List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const CoachingContent = () => {
  useEffect(() => {
    document.title = "Calorie Calculator: Coaching fit call";
  }, []);
  return (
    <Box
      sx={{
        p: 1.75,
        pb: 2.25,
        mt: 1.5,
        backgroundColor: "#ffeee2",
        borderRadius: "14px",
        color: "#231f20",
      }}
    >
      <Box display="flex" alignItems="center" sx={{ mb: 1.5, gap: "10px" }}>
        <Typography
          variant="h2"
          fontWeight="700"
          sx={{
            mb: 0,
            fontFamily: "'Fredoka', sans-serif",
            fontSize: {
              xs: 24,
              sm: 28,
            },
          }}
        >
          Let’s explore coaching
        </Typography>
      </Box>

      <Typography
        variant="h5"
        fontWeight="500"
        sx={{
          mb: "10px",
          fontSize: "16px",
        }}
      >
        1. We’ll explore coaching by first understanding your:
      </Typography>

      <List
        sx={{
          listStyle: "disc",
          paddingLeft: "1.25rem",
          "& .MuiListItem-root": {
            display: "list-item",
          },
          fontSize: "16px",
        }}
        style={{ paddingBottom: 0, paddingTop: 0 }}
      >
        <ListItem
          sx={{
            display: "list-item",
            p: 0,
            my: "7px",
            fontWeight: 500,
          }}
        >
          <Typography variant="p" fontWeight="700" sx={{ mb: 0, mr: 0.5 }}>
            {" "}
            Body:
          </Typography>
          what is normal for you
        </ListItem>

        <ListItem
          sx={{
            display: "list-item",
            p: 0,
            my: "7px",
            fontWeight: 500,
          }}
        >
          <Typography variant="p" fontWeight="700" sx={{ mb: 0, mr: 0.5 }}>
            {" "}
            Emotions:
          </Typography>
          your specific triggers and relationship with food
        </ListItem>

        <ListItem
          sx={{
            display: "list-item",
            p: 0,
            my: "7px",
            fontWeight: 500,
          }}
        >
          <Typography variant="p" fontWeight="700" sx={{ mb: 0, mr: 0.5 }}>
            {" "}
            Beliefs:
          </Typography>
          what you believe about yourself and what’s real
        </ListItem>

        <ListItem
          sx={{
            display: "list-item",
            p: 0,
            my: "7px",
            fontWeight: 500,
          }}
        >
          <Typography variant="p" fontWeight="700" sx={{ mb: 0, mr: 0.5 }}>
            {" "}
            History:
          </Typography>
          what’s worked, not worked, and why.
        </ListItem>
      </List>

      <Typography
        variant="h5"
        fontWeight="500"
        sx={{
          fontSize: "16px",
          margin: "15px 0 10px",
        }}
      >
        2. This will help us create a custom strategy for you so you can:
      </Typography>

      <List
        sx={{
          listStyle: "disc",
          paddingLeft: "1.25rem",
          "& .MuiListItem-root": {
            display: "list-item",
          },
          fontSize: "16px",
        }}
        style={{ paddingBottom: 0, paddingTop: 0 }}
      >
        <ListItem
          sx={{ display: "list-item", p: 0, my: "7px", fontWeight: 500 }}
        >
          <Typography variant="p" fontWeight="700" sx={{ mb: 0, mr: 0.5 }}>
            {" "}
            Food:
          </Typography>
          change only 1-2 habits.
        </ListItem>

        <ListItem
          sx={{ display: "list-item", p: 0, my: "7px", fontWeight: 500 }}
        >
          <Typography variant="p" fontWeight="700" sx={{ mb: 0, mr: 0.5 }}>
            {" "}
            Exercise:
          </Typography>
          do it only if you enjoy it.
        </ListItem>

        <ListItem
          sx={{ display: "list-item", p: 0, my: "7px", fontWeight: 500 }}
        >
          <Typography variant="p" fontWeight="700" sx={{ mb: 0, mr: 0.5 }}>
            {" "}
            Psychology:
          </Typography>
          reprogram your sabotaging behaviors.
        </ListItem>

        <ListItem
          sx={{ display: "list-item", p: 0, my: "7px", fontWeight: 500 }}
        >
          <Typography variant="p" fontWeight="700" sx={{ mb: 0, mr: 0.5 }}>
            {" "}
            Reality:
          </Typography>
          adjust your plan according to your body patterns.
        </ListItem>
      </List>

      <Link
        to="https://www.coachviva.com/product/online-weight-loss-coaching-consultation?utm_source=caloriecalc"
        target="_blank"
        style={{ textDecoration: "none", display: "block" }}
      >
        <Button
          variant="contained"
          sx={{
            px: 2.5,
            py: 1,
            mx: "auto",
            mt: 2.75,
            display: "block",
            background: "#fa6902",
            color: "#fff",
            fontWeight: "700",
            textTransform: "capitalize",
            fontSize: "18px",
            borderRadius: "20px",
            boxShadow: "none",
          }}
        >
          Book Free Consultation Call
        </Button>
      </Link>
    </Box>
  );
};

export default CoachingContent;
