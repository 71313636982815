import { Box, Button, List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const DiyContent = () => {
  useEffect(() => {
    document.title = "Calorie Calculator: DIY pointers call";
  }, []);
  return (
    <Box
      sx={{
        p: 1.75,
        pb: 2.25,
        mt: 1.5,
        backgroundColor: "#ffeee2",
        borderRadius: "14px",
        color: "#231f20",
      }}
    >
      <Box display="flex" alignItems="center" sx={{ mb: 1.5, gap: "10px" }}>
        <Typography
          variant="h2"
          fontWeight="700"
          sx={{
            mb: 0,
            fontFamily: "'Fredoka', sans-serif",
            fontSize: {
              xs: 24,
              sm: 28,
            },
          }}
        >
          We can speed your DIY
        </Typography>
      </Box>

      <Typography
        variant="h5"
        fontWeight="500"
        sx={{
          mb: 1.25,
          lineHeight: "23px",
          fontSize: "16px",
        }}
      >
        Sure thing. We can help you pinpoint your 1 Small Change for Maximum
        Results.
      </Typography>

      <Typography
        variant="h5"
        fontSize={18}
        fontWeight="500"
        sx={{
          mb: 1.25,
          fontSize: "16px",
        }}
      >
        We’ll do this by understanding your:
      </Typography>

      <List
        sx={{
          listStyle: "disc",
          paddingLeft: "1.25rem",
          "& .MuiListItem-root": {
            display: "list-item",
          },
          fontSize: {
            xs: 14,
            sm: 18,
          },
        }}
        style={{ paddingBottom: 0, paddingTop: 0 }}
      >
        <ListItem
          sx={{
            display: "list-item",
            p: 0,
            mb: "5px",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          <Typography variant="p" fontWeight="700" sx={{ mb: 0, mr: 0.5 }}>
            {" "}
            Body:
          </Typography>
          what is normal for you
        </ListItem>

        <ListItem
          sx={{
            display: "list-item",
            p: 0,
            mb: "5px",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          <Typography variant="p" fontWeight="700" sx={{ mb: 0, mr: 0.5 }}>
            {" "}
            Emotions:
          </Typography>
          your specific triggers and relationship with food
        </ListItem>

        <ListItem
          sx={{
            display: "list-item",
            p: 0,
            mb: "5px",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          <Typography variant="p" fontWeight="700" sx={{ mb: 0, mr: 0.5 }}>
            {" "}
            Beliefs:
          </Typography>
          what you believe about yourself and what’s real
        </ListItem>
      </List>

      <Link
        to="https://www.coachviva.com/resources/unstuck-call?utm_source=caloriecalc"
        target="_blank"
        style={{ textDecoration: "none", display: "block" }}
      >
        <Button
          variant="contained"
          sx={{
            px: 2.5,
            py: 1,
            mx: "auto",
            mt: 2.75,
            display: "block",
            background: "#fa6902",
            color: "#fff",
            fontWeight: "700",
            textTransform: "capitalize",
            fontSize: "18px",
            borderRadius: "20px",
            boxShadow: "none",
          }}
        >
          Book Free 15-min Call
        </Button>
      </Link>
    </Box>
  );
};

export default DiyContent;
